import React from 'react';
import { Text, Box } from '@mantine/core';

interface CustomSelectColorizeItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  selected?: string;
  colorMap?: Record<string, string>;
}

const SelectColorizeItems: React.FC<CustomSelectColorizeItemProps> = ({
  label,
  value,
  selected,
  colorMap = {}, // Default to an empty object for flexibility
  ...others
}) => {
  const mergedColorMap = { ...colorMap };

  return (
    <Box
      style={{
        backgroundColor:
          selected === value
            ? '#1c7ed6' // Teal blue
            : mergedColorMap[label] || 'inherit', //Mapped background color
      }}
      {...others}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = selected === value
          ? '#1c7ed6' // Teal blue
          : '#F2F3F5'; // Hover color (light gray)
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = selected === value
          ? '#1c7ed6' // Teal blue
          : mergedColorMap[label] || 'inherit'; // Reset to original color
      }}
    >
      <Text>{label}</Text>
    </Box>
  );
};

export default SelectColorizeItems;
