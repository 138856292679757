'use client';

import { Checkbox as MantineCheckbox } from '@mantine/core';
import { useController } from 'react-hook-form';
import { CheckboxProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function Checkbox(props: CheckboxProps) {
  const { name, label, ...attr } = props;

  const {
    field: { value = false, onChange, ref },
    fieldState: { error },
  } = useController({ name });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    onChange(isChecked);
  };

  return (
    <>
      <MantineCheckbox
        id={name}
        label={label}
        ref={ref}
        checked={value}
        onChange={handleChange}
        //{...attr} // Spread any additional props
      />
      {error && <ErrorMessage>{error.message?.toString()}</ErrorMessage>} {/* Display error message if any */}
    </>
  );
}

export default Checkbox;