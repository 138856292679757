import { DateTimePicker as MantineDateTimePicker } from '@mantine/dates';
import { useController } from 'react-hook-form';
import { DateTimePickerProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function DateTimeInput(props: DateTimePickerProps) {
  const { label, name, onChange, ...rest } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : null;

  return (
    <>
      <MantineDateTimePicker
        id={name}
        label={label}
        valueFormat="DD MMM YYYY HH:mm"
        clearable
        {...rest}
        {...field}
        onChange={(newValue) => {
          field.onChange(newValue);
          if (onChange) onChange(newValue);
        }}
      />
      {error}
    </>
  );
}

export default DateTimeInput;
