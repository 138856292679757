'use client';

import React, { ReactNode } from 'react';
import { Radio, Stack } from '@mantine/core';
import { useController } from 'react-hook-form';

interface User {
  value: string | number | readonly string[] | undefined;
  label: ReactNode;
}

interface SingleSelectGroupProps {
  name: string;
  options?: User[];
  control: any;
}

const SingleSelectGroup: React.FC<SingleSelectGroupProps> = (props) => {
  const { name, options, control } = props;

  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({
    name
  });

  return (
    <>
      <Radio.Group
        value={value}
        onChange={onChange}
        name={name}
        ref={ref} // Connect ref for validation
      >
        <Stack spacing="md">
          {options?.map((user) => (
            <Radio
              key={String(user.value)}
              value={user.value}
              label={user.label}
            />
          ))}
        </Stack>
      </Radio.Group>
      {error && (
        <div style={{ color: 'red', marginTop: '0.5rem' }}>
          {error.message}
        </div>
      )}
    </>
  );
};

export default SingleSelectGroup;
